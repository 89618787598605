/* Auth Pages Css */
.google_button_custom {
  width: 100%;
  display: flex;
  justify-content: center;
}

.Auth2.login_box small {
  color: lightgray;
}

.Auth2 .inpt-fld {
  font-size: smaller;
}

.my-facebook-button-class {
  font-family: Helvetica, sans-serif;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: calc(0.27548vw + 12.71074px);
  text-decoration: none;
  text-transform: none;
  transition: background-color 0.3s, border-color 0.3s;
  background-color: #4c69ba;
  border: calc(0.06887vw + 0.67769px) solid #4c69ba;
  padding: calc(0.3vw + 5px) calc(0.3vw + 18.38843px);
  width: 100%;
}

.FEEDBK .slct-fld {
  width: 100%;
  /* background: #f5f5f5; */
  border: 0;
  padding: 1rem 1.3rem;
  border-radius: 16px;
  background-color: #eeeeee;
  color: #808080d1;
  font-family: "Lato-Bold";
}

.Auth2 .inpt-fld::placeholder {
  opacity: 0.8;
  font-family: "Lato-Regular";
  font-size: smaller;
}

.Auth .Auth-box {
  margin-top: 10rem;
  margin-bottom: 15rem;
}

.Auth .auth-head {
  font-size: 28px;
  font-family: "Poppins-Medium";
  margin-bottom: 3rem;
  font-weight: 900;
  text-align: center;
}

.Auth .pop-reg {
  font-family: "Poppins-Regular";
  font-weight: 500;
}

.Auth .inpt-field {
  background: #f5f5f5;
  border: 0;
  border-radius: 0;
  padding: 1rem 1.5rem;
  margin-bottom: 1.5rem;
  width: 100%;
}

.Auth .inpt-field:focus {
  outline: none;
}

.Auth .d-flex-forg {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.FEEDBK .Auth-box {
  margin-top: 5rem;
}

/* .FEEDBK .inpt-field {
  font-size: 12px;
  background-color: #eeeeee;
  color: #5d5d5d;
} */

.FEEDBK .auth-head {
  font-size: 22px;
  font-weight: 400;
}

.FEEDBK .inpt-field::placeholder {
  color: #9b9b9bbd;
}

.Auth .blu-tx {
  color: #07aaff;
  font-family: "Poppins-Medium";
}

.Auth .px-log {
  padding-left: 3.5rem !important;
  padding-right: 3.5rem !important;
}

.Auth .clr-gry {
  color: #6e6e6e !important;
}

.Auth .entr-code {
  align-items: center;
  text-align: center;
  font-family: "Poppins-Medium";
  font-size: 18px;
}

.Auth .brdr-rd-in {
  border-radius: 13px;
}

.Auth .strzz {
  color: #eeeeee;
  font-size: 40px;
  margin: 0 0.2rem;
}

.Auth .yll {
  color: #ffb433;
}

.Auth .auth-head-zz {
  font-family: "Lato-Heavy";
  font-size: 38px;
  /* font-weight: 900; */
}

/* auth 2 new auth pages without header*/

.Auth2 .clr-gry {
  color: #afafafc9 !important;
  font-size: 10px;
}

.Auth2 {
  background-image: url("../Images/Auth/bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  width: 100%;
}

.Auth2 .login-contnr {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
}

.Auth2 .auth_heading {
  font-family: "Lato-Bold";
  font-size: 48px;
  margin: 0;
  padding-top: 15rem;
  color: white;
}

.Auth2 .login_box {
  background-color: white;
  padding: 2rem;
  /* margin-top: 10rem; */
  padding-bottom: 2.5rem;
}

.Auth2 .sgn-bx-hd {
  font-size: 32px;
  font-family: "Lato-Regular";
  font-weight: 900;
}

.Auth2 .orng-txt {
  font-family: "Poppins-Medium";
  font-size: 13px;
  color: #e77960;
  font-weight: bold;
}

.Auth2 .inpt-fld {
  width: 100%;
  background-color: #eeeeee;
  font-family: "Lato-Bold";
  border: 0;
  padding: 1.2rem;
  border-radius: 16px;
  padding-left: 1.2rem;
}

.Auth2 .inpt-fld:hover {
  outline: 1px solid lightgray;
}

.Auth2 .line-main {
  display: flex;
}

.Auth2 .line1 {
  flex: 3;
  border-bottom: 1px solid #707070bf;
}

.Auth2 .or {
  font-family: "Lato-Regular";
  font-size: 12px;
  color: #9b9b9b;
  flex: 1 1;
  justify-content: center;
  display: flex;
  position: relative;
  top: 9px;
}

.Auth2 .line2 {
  flex: 3;
  border-bottom: 1px solid #707070bf;
}

.Auth2 .alrdd {
  font-family: "Poppins-Medium";
  font-size: 13px;
}

.Auth2 .bt-goog {
  background: white;
  color: #e77960;
  padding: 0.65rem 2.3rem;
  border-radius: 0;
  border: 1px solid #e77960;
  font-size: 13px;
  font-family: "Poppins-Medium";
  font-weight: 600;
  width: 100%;
  border-radius: 10px;
}

.Auth2 .txt-black {
  color: black !important;
}

/* media query css */

@media only screen and (max-width: 992px) {
  .Auth2 .login-contnr {
    min-height: auto !important;
  }
  .Auth2 .auth_heading {
    padding-top: 1.5rem !important;
    font-size: 26px !important;
    text-align: center;
    padding-bottom: 1rem;
  }
  .Auth2 .sgn-bx-hd {
    font-size: 26px;
  }
  .Auth2 .mt-rez-signup {
    margin-top: 0.5rem !important;
  }
  .Auth .auth-head {
    font-size: 26px !important;
  }
  .Forget_Password {
    text-align: center;
  }
  .Forget_Password .Auth-box {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
  .Footer {
    padding-top: 3rem;
    text-align: left;
  }
  .pnc {
    padding-top: 0 !important;
  }
}
