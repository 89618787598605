.Footer {
    background: black;
    color: white;
    padding: 6rem 0;
    padding-bottom: 1rem;
    font-family: "Poppins-Medium";
}

.Footer .footer-txt-field {
    width: 100%;
    padding: 0.6rem 1.4rem;
    border: 0;
    margin-bottom: 0.4rem;
}

.Footer .heading_footer{
    font-size: 14px;
    font-family: "Poppins-Medium";
}

.Footer .news-ltr-txt{
    font-size: 16px;
}

.Footer .txt{
    font-size: 12px;
}

.Footer .chckbx {
    height: 18px;
    width: 16px;
    margin-right: 0.5rem;
    margin-top: 0.1rem;
}

.Footer .insta_icon{
    font-size: 15px;
    position: relative;
    bottom: 2px;
    margin-right: 5px;
}

.Footer .footer-txt-field:focus {
    outline: none;
}

.Footer .lst-p{
    font-family: "Poppins-Medium";
    font-size: 14px;
    margin-top: 4.5rem;
}