/* home slider */
.Homepage .slick-dots li button {
  border-radius: 50%;
  background: #c4c4c400;
  position: absolute;
  left: 215px;
  width: 10px;
  height: 10px;
}

.Homepage .HOME_slct_fld {
  width: 100%;
  /* background: #f5f5f5; */
  border: 0;
  padding: 1.1rem;
  border-radius: 16px;
  background-color: #eeeeee;
  color: #808080d1;
  font-family: "Lato-Bold";
}

.Homepage .slick-dots li button:before {
  font-size: 12px;
}

.Homepage .slick-dots {
  bottom: -45px;
}

.Homepage .slick-dots li {
  margin: 0 2px;
}

.Homepage .slick-dots li.slick-active button:before {
  opacity: 1;
  color: #e37662;
  font-size: 12px;
}

.Homepage .home_first_section {
  display: flex;
  flex-direction: row;
}

.Homepage .home_sml_txt {
  font-size: 13px;
  font-family: "Lato-Regular";
  margin-bottom: 0;
}

.Homepage .home_first_section .content {
  padding-top: 3rem;
  font-size: 48px;
  padding-left: 0;
  flex: 1;
  font-family: "Lato-Regular";
  line-height: 1.4;
}

.Homepage .home_first_section .pl-home-top {
  padding-left: 22%;
  font-family: "Lato-Bold";
  color: #313131;
}

.Homepage .home_first_section .image-bg-content {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* height: 100%; */
  height: 550px;
  width: 100%;
  flex: 1 1;
}

.Homepage .bg_img_hmm {
  background-image: url("../Images/Home/home_img1.webp");
}

.Homepage .home_first_section .make_appointment {}

.Homepage .make_appointment .select-catt {
  font-family: "Lato-Bold";
  font-size: 13px;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.556);
}

.Homepage .make_appointment .make_appointment_box {
  box-shadow: 0px 0px 20px 20px #00000008;
  width: 100%;
  border-radius: 7px;
  position: relative;
  padding: 1.5rem 2rem;
  top: -55px;
  background: #ffffffed;
}

.Homepage .make_appointment .home-select-cus {
  border: 0;
  border-bottom: 1px solid lightgrey;
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
  width: 100%;
  font-size: smaller;
  color: #9f9f9f;
}

.Homepage .we_are_best_sol .we {
  border-top: 3px solid #e77960;
  padding-top: 1.5rem;
}

.Homepage .txt-bll {
  font-size: 12px;
}

.Homepage .we_are_best_sol .we_are_best_sol_P {
  font-size: 35px;
  font-family: "Lato-Bold";
}

.Homepage .we_are_best_sol .ico-head {
  font-size: 18px;
  font-family: "Lato-Heavy";
}

.Homepage .orange_div_home {
  background-image: url("../Images/Home/home_img3.webp");
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 3rem 0;
  padding: 3rem 0;
  color: white;
}

.Homepage .text-light-home {
  color: #fffffff2 !important;
  font-size: 14px;
}

.Homepage .orange_div_home .how_heading {
  font-size: 35px;
  font-family: "Lato-Bold";
}

.Homepage .orange_div_home .select_size {
  font-size: 17px;
  font-weight: 400;
  font-family: "Lato-Regular";
  color: #fffffff2;
  /* font-weight: 700; */
}

.Homepage .orange_div_home .select_sizez {
  font-size: 15px;
  font-weight: 400;
  font-family: "Lato-Regular";
  color: #fffffff2;
  /* font-weight: 700; */
}

.Homepage .cleaning_plan .brdr-top {
  border-top: 3px solid #e77960;
  width: 100px;
  margin: auto;
}

.Homepage .video_home {
  width: 100%;
  /* height: 350px; */
}

.Homepage .cleaning_plan .start_from {
  background-color: black;
  color: white;
  padding: 0.2rem 1.5rem;
  display: inline-block;
  font-size: 11px;
}

.Homepage .cleaning_plan .price {
  font-size: 50px;
  line-height: 01;
  margin-top: 0.75rem;
  color: black;
  font-family: "Lato-Heavy";
}

.Homepage .cleaning_plan .txt-all {
  color: #000000e3;
  font-weight: 600;
  font-size: 15px;
}

.Homepage .cleaning_plan .div_three_box {
  box-shadow: -6px 13px 20px 20px #0000000a;
  border-radius: 6px;
  padding: 2.3rem;
  min-height: 470px;
}

.Homepage .cleaning_plan .bt-bzza {
  position: absolute;
  bottom: 40px;
  left: 32%;
  /* right: 0; */
}

.Homepage .cleaning_plan .time_per_cl {
  font-size: 16px;
  color: #aeaeaebf;
  font-family: "Lato-Bold";
  /* font-family: bold; */
}

.Homepage .cleaning_plan .mt-64 {
  margin-top: 64px;
}

.Homepage .client_says .home_square_bg {
  background-image: url("../Images/Home/home_img4.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  padding: 2rem;
  padding-left: 0;
}

.Homepage .testimonial_img {
  border-radius: 50%;
  object-fit: cover;
}

.Homepage .star_rtng {
  color: #fec42d;
  margin-right: 0.3rem;
}

.Homepage .test-pers {
  font-size: 18px;
  font-family: "Lato-Bold";
}

.Homepage .clr-org {
  color: #e77960;
}

.Homepage .blk-bx-numbrz {
  background: #262626;
  padding: 3rem 0;
  color: white;
  border-radius: 7px;
}

.Homepage .txt-pppp {
  font-size: 17px;
}

.Homepage .numbr_bigg {
  font-size: 58px;
  margin-bottom: 0;
  margin-left: 1rem;
  font-family: "Lato-Bold";
}

.Homepage .proud_prtnr_heading {
  font-size: 45px;
  font-family: "Lato-Bold";
}

.Homepage .proud_partner {
  background-color: #fff2e2;
  padding: 3.8rem 0;
  padding-bottom: 8rem;
}

.Homepage .flx-prtnr {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.Homepage .flx-prtnr div {
  margin: 1.5rem 5rem;
}

.Homepage .keep_clean {
  margin: 3.5rem 0;
}

.Homepage .my-cus-home {
  margin: 7rem 0 5rem 0;
}

/* zamm waork */
.cleaning_plan .sub_heading {
  font-family: "Lato-Bold";
  text-transform: uppercase;
  color: black;
}

@media only screen and (max-width: 992px) {
  body {
    overflow-x: hidden;
  }

  .APP_JS {
    overflow-x: hidden;
  }

  .Homepage .home_first_section {
    flex-direction: column;
  }

  .Homepage .bg_img_hmm {
    height: 610px !important;
    width: 100vw;
    background-position: center;
    z-index: 1;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100% !important;
    padding: 200px;
  }

  .Homepage .home_first_section .pl-home-top {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-bottom: 2rem;
  }

  .Homepage .home_first_section .content {
    font-size: 26px;
    margin-bottom: 1rem;
  }

  .Homepage .make_appointment {
    margin: 1rem 0;
  }

  .Homepage .make_appointment .make_appointment_box {
    position: static;
  }

  .Homepage .pr-res-auto {
    padding: 1rem !important;
  }

  .Homepage .make_appointment .select-catt {
    margin-top: 1rem;
  }

  .Homepage .mt-res-auto {
    margin-top: 1rem !important;
  }

  .Homepage .slick-dots {
    right: 0px;
    left: 0%;
    bottom: -30%;
    transform: translate(-50%, -50%);
    display: none;
  }

  .Homepage .client_says .home_square_bg {
    background-position: right 0;
    background-repeat: no-repeat;
    background-size: 60px;
  }

  .Homepage .numbr_bigg {
    margin-left: 0;
  }

  .Homepage .we_are_best_sol .we_are_best_sol_P {
    font-size: 26px;
  }

  .Homepage .orange_div_home .how_heading {
    font-size: 26px;
  }

  .Homepage .proud_prtnr_heading {
    font-size: 26px;
  }

  .Homepage .heading_home {
    font-size: 26px;
  }

  .mt-res-footer-profile {
    margin-top: 1rem;
  }

  .Homepage .home_img_four_last {
    height: 250px;
    width: 100% !important;
    object-fit: cover;
    border-radius: 30px;
    padding: 1rem;
  }
}