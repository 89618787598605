body {
  overflow-x: hidden !important;
}

.book_btn_service_header {
  display: flex;
  justify-content: space-around;
  align-self: center;
  align-items: center;
  flex: 1;
}

.bt-website-orange {
  background: #e77960;
  color: white;
  padding: 0.65rem 2.3rem;
  border-radius: 0;
  border: 1px solid #e77960;
  font-size: 11px;
  font-family: "Poppins-Medium";
  font-weight: 500;
}

.Header .bt-website-orange {
  padding: 0.65rem 2rem;
  margin-right: 1rem;
}

.goog-te-banner-frame {
  display: none !important;
}

body {
  position: absolute;
  top: 0px !important;
}

iframe{
  display: none;
}

.VIpgJd-ZVi9od-l4eHX-hSRGPd{
  display: none;
}

.VIpgJd-ZVi9od-aZ2wEe-wOHMyf{
  display: none;
}

.VIpgJd-ZVi9od-aZ2wEe-OiiCO{
  display: none;
}

.VIpgJd-ZVi9od-l4eHX-hSRGPd, .VIpgJd-ZVi9od-l4eHX-hSRGPd:link, .VIpgJd-ZVi9od-l4eHX-hSRGPd:visited, .VIpgJd-ZVi9od-l4eHX-hSRGPd:hover, .VIpgJd-ZVi9od-l4eHX-hSRGPd:active{
  display: none!important;
}

.goog-logo-link {
  display: none !important;
}

.goog-te-gadget {
  color: transparent !important;
}

.goog-te-gadget .goog-te-combo {
  color: blue !important;
}

.brdr_top_80px {
  border-top: 4px solid #e77960;
  width: 80px;
  height: 16px;
}

.no_underline {
  text-decoration: none !important;
}

.mapContainerMF {
  width: 100%;
  height: 500px;
}

.bt-blue {
  color: white;
  background: #51b9ff;
  font-size: 13px;
  border: 0;
  border-radius: 0;
  margin-right: 0.5rem !important;
}

.bt-red {
  color: white;
  background: #ff9494;
  font-size: 13px;
  border: 0;
  border-radius: 0;
  /* margin-right: 1rem!important; */
}

.mt-2rem {
  margin-top: 2rem;
}

.txt-all {
  font-size: 14px;
  font-family: "Lato-regular";
  color: #6e6e6e;
}

.align-center {
  align-self: center;
}

.heading_home {
  font-size: 35px;
  font-family: "Lato-Bold";
}

.sub_heading {
  font-size: 30px;
}

.top_brdr_heading {
  border-top: 3px solid #e77960;
  padding-top: 1.5rem;
}

.pos-rel {
  position: relative;
}

.StripeElement {
  padding: 1.4rem 1rem;
  border-radius: 15px;
  border: 0;
  background: whitesmoke;
  background-color: #ebe9e9;
  color: #7c7c7c;
  font-family: "Lato-Bold";
}

.inpt-fld {
  width: 100%;
  background-color: #eeeeee;
  font-family: "Lato-Bold";
  border: 0;
  padding: 1.3rem;
  border-radius: 16px;
  padding-left: 2rem;
  background-color: #ebe9e9;
  color: #7c7c7c;
  font-family: "Lato-Bold";
  font-size: 13px;
}


textarea{
  width: 100%;
  background-color: #eeeeee;
  font-family: "Lato-Bold";
  border: 0;
  padding: 1.3rem;
  border-radius: 16px;
  padding-left: 2rem;
  background-color: #eeeeee;
  color: #7c7c7c;
  font-family: "Lato-Bold";
}

textarea::placeholder{
  color: #7c7c7c;
  font-family: "Lato-Bold";
  font-size: 13px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* .inpt-fld::placeholder {
  color: #9b9b9b;
  font-family: "Lato-Bold";
  font-weight: 800;
  text-shadow: 0px 0 1px #9b9b9b;
} */

.inpt-fld:hover {
  outline: 1px solid lightgray;
}

select {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  background-image: url("../Images/urta_teer.png") !important;
  background-repeat: no-repeat !important;
  background-position-x: 95% !important;
  background-position-y: 2rem !important;
  cursor: pointer;
  color: #7c7c7c;
  font-family: "Lato-Bold";
  font-size: 13px;
}

.Homepage select {
  background-position-y: 1rem !important;
}

select:focus {
  outline: none;
}

input:focus {
  outline: none;
}

.cursor_pointer {
  cursor: pointer !important;
}

.txt-shadd {
  text-shadow: 0 0 black;
}

/* font family starts here */
@font-face {
  font-family: "Poppins-Medium";
  font-style: normal;
  font-weight: normal;
  src: local("Poppins-Medium"),
    url("../Fonts/Poppins-Medium.ttf") format("woff");
}

@font-face {
  font-family: "Poppins-Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Poppins-Regular"),
    url("../Fonts/Poppins-Regular.ttf") format("woff");
}

@font-face {
  font-family: "Montserrat-Light";
  font-style: normal;
  font-weight: normal;
  src: local("Montserrat-Light"),
    url("../Fonts/Montserrat-Light.ttf") format("woff");
}

@font-face {
  font-family: "Montserrat-Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Montserrat-Bold"),
    url("../Fonts/Montserrat-Bold.ttf") format("woff");
}

@font-face {
  font-family: "Lato-Heavy";
  font-style: normal;
  font-weight: normal;
  src: local("Lato-Heavy"), url("../Fonts/Lato-Heavy.ttf") format("woff");
}

@font-face {
  font-family: "Lato-Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Lato-Bold"), url("../Fonts/Lato-Bold.ttf") format("woff");
}

@font-face {
  font-family: "Lato-Light";
  font-style: normal;
  font-weight: normal;
  src: local("Lato-Light"), url("../Fonts/Lato-Light.ttf") format("woff");
}

@font-face {
  font-family: "Lato-Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Lato-Regular"), url("../Fonts/Lato-Regular.ttf") format("woff");
}

@font-face {
  font-family: "Lato-Thin";
  font-style: normal;
  font-weight: normal;
  src: local("Lato-Thin"), url("../Fonts/Lato-Thin.ttf") format("woff");
}

/* font family ends here */

/* google translate mcbc starts*/
.goog-te-gadget {
  font-size: 0;
}

.goog-te-gadget a.goog-logo-link {
  display: none;
}

.goog-te-gadget select.goog-te-combo {
  border: 1px solid #fff !important;
  color: #000 !important;
  font-family: "Lato-bold" !important;
  padding: 0.4rem !important;
  border-radius: 4px !important;
  background-image: url("../Images/Reuseable/down_arr.png") !important;
  background-size: 10px 5px !important;
}

.goog-te-gadget select.goog-te-combo option {
  color: #000;
}

.goog-te-gadget {
  font-size: 0 !important;
}

.skiptranslate.goog-te-gadget div select {
  display: none;
}

.skiptranslate.goog-te-gadget select:last-child {
  display: block !important;
}

.VIpgJd-ZVi9od-l4eHX-hSRGPd {
  display: none !important;
}