.MakeBooking .first_heading {
  padding: 3rem 0 2rem 0;
  font-size: 38px;
  font-family: "Lato-Heavy";
}

.MakeBooking .booking_detials_box .txt-all {
  color: #808080d1 !important;
  font-family: "Lato-Regular";
}

.MakeBooking .address_small_makebooking {
  font-weight: bold;
  margin-left: 0.5rem;
  position: relative;
  top: -1px;
  text-transform: none;
  font-size: 11px;
}

.MakeBooking .subMain_heading {
  font-size: 22px;
  font-family: "Lato-Bold";
}

.MakeBooking .book_ser_final {
  font-size: 13px !important;
}

.MakeBooking .pac-target-input {
  width: 100%;
  border: 0;
  padding: 1.5rem;
  border-radius: 16px;
  padding-left: 2rem;
  color: #808080d1;
  background-color: #ebe9e9;
  color: #7c7c7c;
  font-family: "Lato-Bold";
  font-size: 13px;
}

.MakeBooking .labl {
  font-family: "Lato-Regular";
  font-size: 14px;
  margin-bottom: 0;
  font-weight: 600;
  text-transform: uppercase;
}

.MakeBooking .slct-fld {
  width: 100%;
  text-transform: uppercase;
  /* background: #f5f5f5; */
  border: 0;
  padding: 1.3rem;
  border-radius: 16px;
  background-color: #eeeeee;
  color: #808080d1;
  font-family: "Lato-Bold";
}

/* .MakeBooking .slct-fld::placeholder {
  color: #9b9b9b;
  font-weight: 300;
  font-size: 12px;
  font-family: "Lato-Regular";
} */

.MakeBooking .slct-fld:focus {
  outline: 1px solid #e77960;
}

.MakeBooking .ico_nochk {
  width: 100%;
  height: 50px;
  max-width: 80px;
}

.MakeBooking .justify-space-between {
  justify-content: space-between;
}

.MakeBooking .bg_orgn_no {
  width: auto;
  padding: 2.5rem;
  border-radius: 16px;
  background-color: #ebe9e9;
  color: #7c7c7c;
  font-family: "Lato-Bold";
  font-size: 13px;
}

.MakeBooking .bg_orgn {
  width: auto;
  background: #e77960;
  color: white;
  padding: 2.5rem;
  font-family: "Lato-Bold";
  font-size: 13px;
  border-radius: 16px;
}

.MakeBooking .chk-bx {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0;
  top: 0%;
  cursor: pointer;
  left: 1%;
}

.MakeBooking .pad-sml {
  padding: 1.3rem;
  border-radius: 15px;
  text-align: center;
}

.MakeBooking .paymnt_head {
  font-family: "Lato-Bold";
  font-size: 17px;
}

.MakeBooking .crdt-crd {
  font-family: "Lato-Bold";
  color: black;
}

.MakeBooking .booking_detials_box {
  margin-top: 3rem;
  /* background: whitesmoke; */
  padding: 1.5rem;
  padding-right: 0.5rem;
  border-radius: 16px;
  background-color: #ebe9e9;
  color: #808080d1;
  font-family: "Lato-Bold";
}

.MakeBooking .booksmry {
  color: #404040;
  font-size: 20px;
  font-family: "Lato-Bold";
  border-bottom: 1px solid lightgrey;
  width: 95%;
  padding-bottom: 0.8rem;
  padding-left: 1rem;
}

.MakeBooking .lst-crcl {
  list-style-type: circle;
}

.MakeBooking .orng-lrg-txt {
  font-size: 42px;
  font-family: "Lato-Heavy";
  color: #e77960;
}

.MakeBooking .Booking_TOT {
  font-size: 20px;
  font-family: "Lato-Bold";
  color: black;
  margin-top: 2rem;
  border-radius: 16px;
}

/* new booking page */
.chk-txt {
  font-size: 13px;
  font-family: "Lato-Regular";
  color: #9b9b9b;
  vertical-align: text-bottom;
}

@media only screen and (max-width: 992px) {
  .MakeBooking .first_heading {
    font-size: 26px !important;
    /* padding-bottom: 0 !important;
    margin-bottom: 0 !important; */
  }
  .MakeBooking .subMain_heading {
    font-size: 20px !important;
  }
  .MakeBooking .reszz_checkout_top0 {
    margin-top: 0 !important;
  }
  .MakeBooking .mt-rezz-mt-2 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .MakeBooking .booking_detials_box {
    margin-top: 0 !important;
    margin-bottom: 3rem !important;
  }
  .MakeBooking .pl_res_checkout_0 {
    padding-left: 1rem !important;
  }
}