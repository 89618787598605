.TermsAndCondition .TnC_heading {
    font-size: 42px;
    font-family: "Lato-Regular";
    padding-top: 8rem;
}

.TermsAndCondition .TnC_txt {
    font-family: "Lato-Regular";
    font-size: 16px;
}

.TermsAndCondition .mb-rezz {
    margin-bottom: 7rem;
}

@media only screen and (max-width: 992px) {
    .TermsAndCondition .TnC_heading {
        padding-top: 1.5rem;
        font-size: 26px;
    }

    .TermsAndCondition .TnC_txt * {
        color: rgb(110, 110, 110) !important;
        font-family: "Lato-regular" !important;
        font-size: 14px !important;
    }

    .TermsAndCondition .mb-rezz {
        margin-bottom: 2.5rem !important;
    }
}