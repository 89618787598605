.Header {
  /* position: absolute; */
  top: 0;
  width: 100%;
  z-index: 99;
  font-family: "Lato-Bold";
}

.header-full {
  display: flex;
  flex-direction: row;
}

.header-full .logo-white-div {
  flex: 3;
}

.header-full .black-div {
  align-items: center;
  background: #3d3d3d;
  color: #fff;
  display: flex;
  flex: 9 1;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 1.5rem;
  padding: 0.5rem 0;
  z-index: 111;
}

.header-full .pnt-rtng {
  font-family: "Lato-Bold";
  font-size: 24px;
  margin-bottom: 0;
  text-shadow: 0 0 1px black;
}

.header-full .header_logo_here {
  width: 200px;
  object-fit: contain;
  height: 80px;
  /* height: 100px; */
  box-sizing: border-box;
  margin-top: 0.5rem;
}

.header-full .pnt-lylty {
  font-size: 12px;
  text-shadow: 0 0 1px #e77960;
  color: #e77960;
  font-weight: 900;
  position: relative;
  top: -7px;
  line-height: 1.2;
  font-family: "Lato-Bold";
}

.header-full .loyality_points {
  position: absolute;
  right: 0;
  top: 74px;
  z-index: 99;
  background: white;
  border-radius: 0px 0 0 66px;
  padding-bottom: 1rem;
  padding-top: 0.75rem;
  padding-right: 0.5rem;
  padding-left: 1.6rem;
  box-shadow: 1px 2px 5px #00000042;
}

.header-full .links-pages {
  background: transparent;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  flex: 3;
}

.header-full .links-pages p {
  margin: 0 1.5rem 0 0;
}

.header-full .no_link {
  text-decoration: none;
  color: white;
  margin: 0 0.8rem;
  text-align: center;
}

@media only screen and (max-width: 1299px) {
  .header-full .no_link {
    font-size: 12.5px;
  }
}

/* mobile header responsive header */
.header-small {
  display: none;
}

.swip_header_sml {
  display: none;
}

@media only screen and (max-width: 992px) {
  .header-full {
    display: none;
  }

  .header_comp {
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 999;
  }

  .swip_header_sml {
    display: flex;
    justify-content: center;
    margin-top: 3px;
  }

  .facebook_ico_headerr {
    color: #202076;
  }

  .insta_ico_headerr {
    color: #ff607a99;
  }

  .header-small {
    display: block;
  }

  .header .flx-header-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .header-small .img_respon {
    width: 80px;
    height: 50px;
    padding: 0px !important;
    background-color: #fff0 !important;
    /* width: 30px; */
    object-fit: contain;
  }

  .header-small .sml_hdr_mob_maple {
    /* width: 100%; */
    text-align: center;
    /* margin-left: 15%; */
  }

  .header-small .line-small-hdr {
    margin-left: 10px;
  }

  .header-small .bg-red-hdr {
    background-color: #000;
    color: white;
    display: flex;
    /* justify-content: space-between; */
    padding: 0rem 0.5rem;
    padding-left: 0;
  }

  .header-small .icon_profile_sml_hdr {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-self: center;
    align-items: center;
}

  .header-small .align-center {
    align-self: center;
  }

  .header-small .clr_balluu {
    color: white;
    font-size: 18px;
  }

  .header-small .bt-book-instant-mob {
    background-color: #dc725f;
    font-size: 10px;
    padding: 0.5rem 1.5rem;
    margin-left: 1rem;
    color: white;
    border-radius: 0;
  }

  .clr-hom-sml-hdr {
    color: #dc725f;
    text-decoration: none;
  }

  .clr-hom-sml-hdr :hover {
    text-decoration: none;
    color: #dc725f;
    /* border-bottom: 1px solid #df1921; */
    /* padding-bottom: 0.5rem; */
  }

  .list-style-type-circle {
    list-style-type: circle;
  }

  .flx-1 {
    flex: 1;
  }

  .flx-2 {
    flex: 3.5;
  }

  .flex-end-hdr-smll {
    justify-content: flex-end;
    cursor: pointer;
    color: #df1921;
  }

  .flex-end-hdr-smll:hover {
    color: #202076;
  }

  .close_btn_sml_hdr {
    float: right;
    margin-right: 1rem;
  }

  .MuiDrawer-root>.MuiPaper-root {
    border-radius: 0px 0px 8px 8px;
  }

  .swip_header_sml .css-1ytz6dp {
    background-color: #000;
  }

  .pos-rel-top-four {
    position: relative;
    top: 3px;
    left: 12px;
    font-size: 12px;
  }

  .content_control_swiphead_res_header {
    background-color: #00000012;
    width: 100vw;
    height: 100%;
    padding: 10px 30px;
  }

  .flx_swipheader_res {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 1.5rem;
  }

  .header-small .flx_profile_img {
    place-content: center;
    align-items: center;
    display: flex;
    width: 100%;
  }

  .header-small .flx_profile_img .profile_image_headers {
    margin-right: 0.5rem;
    position: static;
  }
}