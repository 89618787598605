/* about us page starts*/
.AboutPage .pos-rel-lft {
  position: relative;
  left: -10px;
}

.AboutPage .abt-txt {
  font-size: 16px;
  font-family: "Lato-regular";
  color: #6e6e6eab;
}

.AboutPage .about-cnt {
  /* background-image: url("../Images/About/About.png"); */
  height: 680px !important;
  z-index: -1;
}

.AboutPage .about-subhead {
  font-size: 26px;
}

.AboutPage .wdth-115 {
  width: 115%;
  max-width: 620px;
}

.AboutPage .mb-ftr {
  margin-bottom: 5rem;
}

@media only screen and (max-width: 992px) {
  .AboutPage .about-cnt {
    height: 610px !important;
    width: 100vw !important;
    background-position: center !important;
    z-index: 999 !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    width: 100% !important;
    /* opacity: 999!important; */
    padding: 200px !important;
  }

  .AboutPage .bt-website-orange {
    margin-top: 2rem;
  }

  .AboutPage .col-lg-3 img {
    max-height: 350px;
    object-fit: cover;
    border-radius: 0px;
    padding: 1rem;
    padding-left: 0;
  }

  .AboutPage .txt-shadd {
    font-size: 26px !important;
  }

  .AboutPage .about-subhead {
    font-size: 20px !important;
  }
}

/* about us page ends*/

/* faq page starts  */
.Faq .faq_content {
  padding-top: 3rem;
  margin-bottom: 5rem;
}

.Faq .acrdn_summry {
  background-color: #eeeeee;
  color: black;
  padding: 0.5rem 3rem;
  border-radius: 15px;
}

.Faq .acrdn_details {
  border: 0;
  background-color: transparent;
  padding-left: 2rem;
  padding-top: 1rem;
}

.Faq .acrdn_main {
  border: 0;
  background-color: transparent;
  box-shadow: unset;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root:before {
  background-color: transparent !important;
}

.css-67l5gl::before {
  background-color: transparent !important;
}

.Faq .faq_acrdn_heading {
  margin-bottom: 0;
  font-family: "Lato-Bold";
}

.Faq .main_faq_head {
  font-size: 42px;
  font-family: "Lato-Bold";
  margin-bottom: 2rem;
  text-shadow: 0 0 1px black;
}

@media only screen and (max-width: 992px) {
  .Faq .faq_acrdn_heading {
    font-size: 14px;
    margin-right: 1rem;
  }

  .Faq .acrdn_details * {
    font-size: 12px !important;
  }

  .Faq .main_faq_head {
    font-size: 26px !important;
  }
}

/* faq page ends  */

/* blog page start */
.InnerService .set_round_inner {
  border-radius: 50%;
  height: 70px;
  width: 70px;
}

.blog .text_lineLimit {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}

.blog .atcvv_btnz {
  background-color: #0d0d0d !important;
  color: white !important;
}

.blog .bloggg-cont {
  min-height: 300px;
  position: relative;
}

.blog .main_blog_content {
  padding-top: 2.5rem !important;
}

.blog .bt-bloggg {
  position: absolute;
  bottom: 10px;
}

.blog .bloggg-cont {
  min-height: 200px;
  position: relative;
}

.blog .main_blog_content {
  padding-top: 8rem;
  margin-bottom: 7rem;
}

.blog .main_blog_head {
  font-size: 42px;
  font-family: "Lato-Heavy";
  color: black;
  /* margin-bottom: 2rem; */
}

.blog .bt-blk {
  background-color: #363636 !important;
  border: 1px solid #363636;
}

.bt-blk:hover,
.bt-blk:focus {
  color: white;
}

.blog .item_heading {
  font-family: "Poppins-Regular";
  font-size: 16px;
  margin-bottom: 0.5rem;
  font-weight: 600;
}

.blog .crd-bxshd {
  box-shadow: 1px 5px 20px #0000000f;
  min-height: 320px;
}

.blog .pos-rel-btn-bx {
}

.blog .bt-pagi {
  background-color: #fff;
  color: black;
  border: 0;
  border-radius: 0;
}

.blog .bt-pagi:hover,
.blog .bt-pagi:focus {
  background-color: #363636;
  color: white;
}

.blog .txt-dngrsss {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media only screen and (max-width: 992px) {
  .bt-pagi {
    font-size: 12px;
  }

  .blog .main_blog_head {
    font-size: 26px !important;
  }
}

/* blog page end */

/* conatct page start */
.pos-abs-btz {
  position: absolute;
  bottom: 24px;
}

.blog .lbl-field {
  color: #0d0d0d;
  /* text-shadow: 0 0 1px black; */
  font-family: "Lato-Heavy";
  font-size: 15px;
  margin-bottom: 0.5rem;
}

/* .blog::placeholder {
  opacity: 0.8;
  font-family: "Lato-Regular";
  font-size: smaller;
} */

.blog .main_blog_content {
  padding-top: 8rem;
  margin-bottom: 2.9rem;
}

.blog .inpt-fld {
  width: 100%;
  font-size: smaller;
  border: 0;
  padding: 1.3rem;
  border-radius: 16px;
  padding-left: 2rem;
  background-color: #eeeeee;
  font-family: "Lato-Bold";
}

.blog .inpt-fld-txt-area {
  width: 100%;
  background-color: #eeeeee;
  font-family: "Lato-Bold";
  border: 0;
  padding: 1rem;
  border-radius: 16px;
  padding-left: 2rem;
  resize: none;
  color: #7c7c7c;
}

.blog .inpt-fld-txt-area:hover,
.blog .inpt-fld-txt-area:focus {
  outline: 1px solid lightgray;
}

.blog .inpt-fld:focus,
.blog .inpt-fld:hover {
  outline: 1px solid lightgray;
}

.blog .flx_main_div {
  display: flex;
  flex-direction: row;
  position: relative;
  top: 3rem;
}

.blog .prt_1 {
  background: url("../Images/Contact/cnt1.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  flex: 1;
}

.blog .prt_3 {
  background: url("../Images/Contact/cnt2.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  flex: 1;
}

.blog .prt_2 {
  background-color: #e77960;
  flex: 1 1;
  padding: 7rem 0;
  justify-content: center;
  align-self: center;
  align-items: center;
  color: white;
}

.blog .tlk-cap {
  font-size: 42px;
  font-family: "Lato-Regular";
  margin-bottom: 2rem;
}

.blog .tlk {
  font-size: 16px;
  font-family: "Lato-Bold";
  /* margin-bottom: 2rem; */
}

.contact .font-size-head-cont {
  font-size: 36px;
}

@media only screen and (max-width: 992px) {
  .main_blog_content .left_res_contact_0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .blog .flx_main_div {
    flex-direction: column;
    top: 0rem;
  }
  .blog .prt_1 {
    padding: 150px;
  }
  .blog .prt_2 {
    width: 100%;
  }
  .blog .prt_3 {
    padding: 150px;
  }
  .blog .main_blog_content {
    margin: 0 0.5rem;
    margin-bottom: 1rem;
  }
  .blog .mt-cuszz {
    padding-top: 0 !important;
  }
  .blog .col-lg-4 .card_gift {
    margin-top: 1.25rem !important;
  }
}

/* contact page end */

/* gift starts*/
.blog .img_phool {
  background-image: url("../Images/Gift/gift.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 361px;
  position: absolute;
  bottom: 0;
}

.blog .bill_Gifty_BC {
  margin-top: 2.7rem;
  /* background: whitesmoke; */
  padding: 1rem;
  padding-right: 0.5rem;
  border-radius: 16px;
  background-color: #ebe9e9;
  color: #808080d1;
  font-family: "Lato-Bold";
  box-shadow: 0px 0px 8px 0px #00000085;
}

.blog .layout_matchhhed {
  padding: 1.2rem 1.8rem;
}

.blog .checkout {
  font-size: 20px;
  font-family: "Lato-regular";
  margin: 1.5rem 0;
}

.blog .card_gift {
  background: #e77960;
  border-radius: 15px;
  box-shadow: 0px 0px 8px 0px #00000085;
  padding: 1.5rem;
  border: 1px solid #e77960;
  color: white;
  margin-top: 5.25rem !important;
}

.blog .bhool {
  height: 472px !important;
}

.blog .slct-fld {
  width: 100%;
  background: #eeeeee;
  border: 0;
  padding: 1.2rem;
  border-radius: 16px;
  color: #7c7c7c;
}

.blog .slct-fld2{
  padding: 1.3rem !important;
    border-radius: 16px !important;
    padding-left: 2rem !important;
}

.blog .text-align-lft {
  text-align: left !important;
}

.blog .messg_box {
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* gift end */

/* career strats */

.career .slct-fld {
  color: #7c7c7c;
  font-size: 13px;
  font-weight: 500;
  width: 100%;
  background: #eeeeee;
  border: 0;
  padding: 1.3rem;
  border-radius: 16px;
  font-family: "Lato-Bold";
}

.career .imaaa {
  width: 400px !important;
}

/* .career .inpt-fld::placeholder {
  font-size: 12px;
  font-family: "Lato-Regular";
  font-weight: 500;
} */

.career .img_phool {
  height: 585px;
}

.career .mb-many {
  margin-bottom: 15rem;
}

.career .pos-imgg {
  position: absolute;
  bottom: 0;
  right: 0;
}

.career .abt-hm {
  font-family: "Lato-Regular";
  font-weight: 600;
  color: #111111;
  margin-bottom: 0;
}

.career .upld-orng {
  color: #e77960;
  font-size: 13px;
  font-weight: 500;
  font-family: "Lato-Regular";
  cursor: pointer;
}

.career .inpt-fld {
  padding: 1rem 1.5rem !important;
  background-color: #eeeeee !important;
}

.career .upld {
  position: absolute;
  z-index: 1;
  /* visibility: hidden; */
  opacity: 0;
  cursor: pointer;
}

@media only screen and (max-width: 992px) {
  .career .main_blog_head {
    margin-top: 0rem !important;
  }
  .career .imaaa {
    width: 200px !important;
  }
}