.InnerService .bg_img_div {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* padding: 15rem 0; */
  min-height: 480px;
}

.InnerService .bt-bknowa {
  background-color: #000000db;
  border: 0;
}

.InnerService .heading_one {
  font-family: "Poppins-Regular";
  font-size: 32px;
}

.InnerService .txtz {
  font-family: "Poppins-Regular";
  font-size: 15px;
  color: #77838f;
}

.InnerService .banda_detail {
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
  padding: 1rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.InnerService .pro_imgg {
  width: 50px;
  height: 50px;
}

.InnerService .pers_name {
  margin-bottom: 0;
  font-family: "Poppins-Regular";
  font-size: 14px;
}

.InnerService .pub_det {
  margin-bottom: 0;
  font-family: "Poppins-Regular";
  font-size: 14px;
  color: #77838f;
}

.InnerService .social_ico {
  color: #fbc239;
}

.InnerService .blu_dv {
  background: #f4f8ff;
  padding: 1.5rem;
}

.InnerService .blu_dv_big {
  background: #f4f8ff;
  font-size: 20px;
  font-family: "Poppins-Regular";
}

.InnerService .blu_name {
  font-size: 12px;
  font-family: "Poppins-Regular";
}

.InnerService .bg-head-ll {
  font-family: "Poppins-Regular";
  font-size: 28px;
  font-weight: 900;
}

.InnerService .list-ul li {
  list-style-type: disc;
}

.InnerService .list-ul {
  padding-left: 1rem;
}

.InnerService .mb-ftr {
  margin-bottom: 5rem;
}

@media only screen and (max-width: 992px) {
  .InnerService .banda_detail{
    flex-direction: column;
    justify-content:flex-start ;
    align-items: start;
  }
  .InnerService .flx-col-respnsv{
    flex-direction: column;
  }
  .InnerService .ml-res-left-social-0{
    margin-left: 0!important;
  }
}